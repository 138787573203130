const Ziggy = {
  url: 'http://smartsquare-2024.test',
  port: null,
  defaults: {},
  routes: {
    'endpoints.jobs.index': { uri: 'api/jobs', methods: ['GET', 'HEAD'] },
    'endpoints.jobs.show': {
      uri: 'api/jobs/{job}',
      methods: ['GET', 'HEAD'],
      parameters: ['job'],
      bindings: { job: 'slug' },
    },
    'forms.contact': { uri: 'api/forms/contact', methods: ['POST'] },
  },
}
if (typeof window !== 'undefined' && typeof window.Ziggy !== 'undefined') {
  Object.assign(Ziggy.routes, window.Ziggy.routes)
}
export { Ziggy }
